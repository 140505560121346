
import { mapGetters } from "vuex";
import Vue from "vue";
import NotificationsLoading from "@/components/NotificationsLoading.vue";

export default Vue.extend({
  name: "Notifications",
  components: {
    // InfiniteLoading,
    NotificationsLoading
  },
  props: {
    profile: Object
  },
  data() {
    return {
      page: 1,
      list: [] as any,
      lastPage: 0,
      loading: false,
      isDefault: false
    };
  },
  computed: {
    ...mapGetters({
      notifications: "notification/getNotifications"
    })
  },
  methods: {
    profileLink(data: any) {
      const type = data.user.type == "mentee" ? "mentorees" : "mentors";
      const path = `/${type}/${data.user.uuid}`;
      // if (this.$route.path !== path) this.$router.push(path);
      return path;
    },
    getNotifications(page = 1, isDefault = false): void {
      if (isDefault && this.isDefault) {
        return;
      }
      if (isDefault) {
        this.isDefault = true;
        this.loading = true;
      }
      this.$store
        .dispatch("notification/GET_NOTIFICATIONS", { page })
        .then(data => {
          if (data.data.length > 0) {
            this.lastPage = data.last_page;
            this.list.push(...data.data);
          }
          this.loading = false;
        });
    },
    newNotification(notification: any) {
      if (this.list.length) {
        this.list.unshift(notification);
      }
    },
    markAsRead() {
      this.$store.dispatch("notification/MARK_AS_READ").then(() => {
        this.$store.commit("profile/SET_NOTIFICATION_COUNT", 0);
        this.list = [];
        this.page = 1;
        this.lastPage = 0;
        this.getNotifications(1);
      });
    },
    markOneAsRead(id: any) {
      const index = this.list.findIndex((i: any) => i.id == id);
      if (index > -1 && !this.list[index].read_at) {
        this.$store.dispatch("notification/MARK_ONE_AS_READ", id).then(() => {
          this.list[index].read_at = new Date().toISOString();
          this.$store.commit("profile/DECREASE_NOTIFICATION_COUNT");
        });
      }
    },
    handleScroll({
      target: { scrollTop, clientHeight, scrollHeight }
    }: {
      target: any;
    }) {
      if (
        scrollTop + clientHeight >= scrollHeight - 30 &&
        !this.loading &&
        this.page <= this.lastPage - 1
      ) {
        this.page += 1;
        this.getNotifications(this.page);
      }
    },
    getNotificationText(text: string) {
      const pattern = /notification\.[a-zA-Z]+/;
      const match = pattern.exec(text);
      const extractedText = match ? match[0] : null;

      if (extractedText) {
        const notificationText = text.replace(
          pattern,
          (this as any).$t(extractedText)
        );
        return notificationText;
      } else {
        return text;
      }
    }
  }
});
