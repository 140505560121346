
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Sidebar",
  props: {
    menuItems: Array,
    profileMenu: Array,
    search: Function
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  }
});
