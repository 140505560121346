
import Vue from "vue";
import SearchResults from "@/components/SearchResults.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "HeaderSearch",
  components: {
    SearchResults
  },
  computed: {
    ...mapGetters({
      setting: "helper/getSetting"
    })
  }
});
