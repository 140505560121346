
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "HeaderLanding",

  computed: {
    ...mapGetters({
      mentorRegistration: "helper/getMentorRegistration",
      version: "helper/getVersion",
      setting: "helper/getSetting"
    })
  },
  mounted() {
    this.$store.dispatch("helper/GET_MENTOR_REGISTRATION");
    this.$store.dispatch("helper/GET_VERSION");
  }
});
