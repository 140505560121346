
import Vue from "vue";
import { mapGetters } from "vuex";
import TranslationHelper from "@/helpers/translation";

export default Vue.extend({
  name: "LanguageSelector",
  data() {
    return {
      selectedLanguage: undefined,
      OpenIndicator: {
        render: (createElement: any) =>
          createElement("inline-svg", {
            attrs: {
              src: require(`@/assets/icons/arrow.svg`)
            }
          })
      },
      Deselect: {
        render: () => null
      }
    };
  },
  computed: {
    ...mapGetters({
      languages: "translation/getLanguages",
      currentLanguage: "translation/getCurrentLanguage"
    })
  },
  watch: {
    currentLanguage: {
      handler(newVal) {
        this.selectedLanguage = newVal;
      },
      immediate: true
    }
  },
  mounted() {
    console.log(this.OpenIndicator);
    this.getLanguages();
  },
  methods: {
    onChange(value: any) {
      localStorage.setItem("language", value);
      window.location.reload();
    },
    getLanguages() {
      this.$store.dispatch("translation/GET_LANGUAGES");
    }
  }
});
