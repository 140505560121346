
import Vue from "vue";

export default Vue.extend({
  name: "HeaderDiscoverDropdown",
  props: {
    profileDropdownMenu: Array,
    profileImage: String
  },
  methods: {
    profileLink() {
      const path = "/profile";
      if (this.$route.path !== path) this.$router.push(path);
    }
  }
});
