
import Vue from "vue";

export default Vue.extend({
  name: "HeaderMentoringsDropdown",
  props: {
    mentoringsDropdownMenu: Array,
    userType: String
  }
});
