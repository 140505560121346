
import Vue from "vue";
import { BIcon } from "bootstrap-vue";
import Sidebar from "@/components/Sidebar.vue";
import { mapGetters } from "vuex";
import Notifications from "@/components/Notifications.vue";
import NotificationToaster from "@/components/NotificationToaster.vue";
import Socket from "@/services/Socket";
import RegisterModal from "@/components/Modals/RegisterModal.vue";
import CookiesModal from "@/components/Modals/CookiesModal.vue";
import HeaderLanding from "@/components/Header/HeaderLanding.vue";
import HeaderRegister from "@/components/Header/HeaderRegister.vue";
import HeaderSearch from "@/components/Header/HeaderSearch.vue";
import HeaderMenu from "@/components/Header/HeaderMenu.vue";
import HeaderMentoringsDropdown from "@/components/Header/HeaderMentoringsDropdown.vue";
import HeaderDiscoverDropdown from "@/components/Header/HeaderDiscoverDropdown.vue";
import HeaderMessages from "@/components/Header/HeaderMessages.vue";
import HeaderProfile from "@/components/Header/HeaderProfile.vue";
import LanguageSelector from "@/components/Header/LanguageSelector.vue";

export default Vue.extend({
  name: "Header",
  components: {
    HeaderLanding,
    HeaderRegister,
    HeaderSearch,
    HeaderMenu,
    HeaderMentoringsDropdown,
    HeaderDiscoverDropdown,
    HeaderMessages,
    HeaderProfile,
    Notifications,
    NotificationToaster,
    BIcon,
    Sidebar,
    RegisterModal,
    CookiesModal,
    LanguageSelector
  },
  props: {
    headerStyle: String as any,
    isTopLoading: Boolean,
    isRegister: Boolean
  },
  data() {
    return {
      loading: true,
      manageAgenda: true,
      notification: {},
      // Menu buttons
      menu: [
        {
          name: (this as any).$t("headerMenu.headerMenuItemFeed"),
          icon: "feed-icon",
          action: () => {
            const path = "/news-feed";
            if (this.$route.path !== path) this.$router.push(path);
          }
        },
        {
          name: (this as any).$t("headerMenu.headerMenuItemAgenda"),
          icon: "calendar",
          action: () => {
            const path = "/agenda";
            if (this.$route.path !== path) this.$router.push(path);
          }
        }
      ],
      // Profile dropdown menu items
      profileDropdownMenu: [
        [
          {
            name: (this as any).$t("headerMenu.headerMenuItemProfile"),
            icon: "user",
            action: () => {
              const path = "/profile";
              if (this.$route.path !== path) this.$router.push(path);
            }
          },
          {
            name: (this as any).$t("headerMenu.headerMenuItemSettings"),
            icon: "settings",
            action: () => {
              const path = "/settings";
              if (this.$route.path !== path) this.$router.push(path);
            }
          }
        ],
        [
          {
            name: (this as any).$t("headerMenu.headerMenuItemWallet"),
            icon: "credit-card-payment",
            text: this.$store.getters.wallet?.total,
            action: () => {
              const path = "/wallet";
              if (this.$route.path !== path) this.$router.push(path);
            }
          },
          {
            name: (this as any).$t("headerMenu.headerMenuItemStatistics"),
            icon: "platform",
            action: () => {
              const path = "/statistics";
              if (this.$route.path !== path) this.$router.push(path);
            }
          },
          {
            name: (this as any).$t("headerMenu.headerMenuItemRatings"),
            icon: "rating",
            action: () => {
              const path = "/ratings";
              if (this.$route.path !== path) this.$router.push(path);
            }
          }
        ],
        [
          {
            name: (this as any).$t("headerMenu.headerMenuItemLogOut"),
            icon: "logout",
            action: () =>
              this.$store
                .dispatch("auth/LOGOUT_USER")
                .then(() => this.$router.push("/"))
          }
        ]
      ],
      discoverDropdownMenu: [
        /*{
          name: "Discover Mentorcasts",
          icon: "scene",
          status : 1,
          action: () => {
            const path = "/discover/mentorcasts";
            if (this.$route.path !== path) this.$router.push(path);
          }
        },*/
        {
          name: (this as any).$t("headerMenu.headerMenuItemDiscoverMentors"),
          icon: "discover-mentor",
          status: 1,
          action: () => {
            const path = "/discover/mentors";
            if (this.$route.path !== path) this.$router.push(path);
          }
        },
        {
          name: (this as any).$t("headerMenu.headerMenuItemDiscoverPeople"),
          icon: "3users",
          status: 1,
          action: () => {
            const path = "/discover/people";
            if (this.$route.path !== path) this.$router.push(path);
          }
        }
      ],
      mentoringsDropdownMenu: [
        [
          /*{
            name: "Launch Mentorcasts",
            icon: "mentorcasts-mentor",
            action: () => {
              const path = "/mentorcasts/create";
              if (this.$route.path !== path) this.$router.push(path);
            }
          },*/
          /*{
            name: "Open Session Slots",
            icon: "tea-icon-mentor",
            action: () => {
              const path = "/agenda";
              if (this.$route.path !== path)
                this.$router.push({
                  path: path,
                  //@ts-ignore
                  query: {manageAgenda: true}
                });
            }
          },*/
          /*{
            name: "Create Program",
            icon: "programs",
            action: () => {
              const path = "/mentoring-programs/create";
              if (this.$route.path !== path) this.$router.push(path);
            }
          }*/
        ],
        [
          /*{
            name: "My Mentorcasts",
            icon: "scene",
            action: () => {
              const path = "/mentorcasts";
              if (this.$route.path !== path) this.$router.push(path);
            }
          },*/
          /*{
            name: "My Sessions",
            icon: "tea-table",
            action: () => {
              const path = "/sessions";
              if (this.$route.path !== path)
                if (this.$route.path !== path) this.$router.push(path);
            }
          },*/
          /*{
            name: "My Programs",
            icon: "program-price",
            action: () => {
              const path = "/mentoring-programs";
              if (this.$route.path !== path)
                if (this.$route.path !== path) this.$router.push(path);
            }
          },*/
          /*{
            name: "My Library",
            icon: "my-library",
            action: () => {
              const path = "/my-library";
              if (this.$route.path !== path)
                if (this.$route.path !== path) this.$router.push(path);
            }
          },*/
          {
            name: (this as any).$t("headerMenu.headerMenuItemMyCertificate"),
            icon: "certificate",
            action: () => {
              const path = "/my-certificate";
              if (this.$route.path !== path)
                if (this.$route.path !== path) this.$router.push(path);
            }
          }
        ]
      ]
    };
  },
  computed: {
    /* headerStyle(): string | null {
      if (this.$route.name == "LandingPage" && this.setting.header_color) {
        return `background:${this.setting.header_color}`
      } else if (this.$route.name != "LandingPage" && this.setting.global_header_color) {
        return `background:${this.setting.global_header_color}`
      }

      return null;
    },*/
    /*mentoringSessionStatus():any{
       return this.moduleSettings['mentoring_session'] == 1;
    },
    mentorcastStatus():any{
      return this.moduleSettings['mentorcast'] == 1;
    },
    mentoringProgramStatus():any{
      return this.moduleSettings['mentoring_program'] == 1;
    },
    mentorLibraryStatus():any{
      return this.moduleSettings['mentor_library'] == 1;
    },
    feedbackEngineStatus():any{
      return this.moduleSettings['feedback_engine'] == 1;
    },
    mentorCommunityStatus():any{
      return this.moduleSettings['mentor_community'] == 1;
    },
    sessionCertificateStatus():any{
      return this.moduleSettings['session_certificate'] == 1;
    },*/
    ...mapGetters({
      isAuthorized: "auth/getAuthorize",
      /*moduleSettings: "helper/getModuleSettings",*/
      profile: "profile/getProfile",
      activeChatId: "chat/getActiveChatId",
      chats: "chat/getChats",
      setting: "helper/getSetting"
    })
  },
  async beforeMount() {
    this.$emit("setLoading", true);
    this.$store.dispatch("helper/GET_SETTING");
    const moduleSettings: any = await this.$store.dispatch(
      "helper/GET_MODULE_SETTINGS"
    );
    /*console.log('moduleSettings -> ', moduleSettings)*/
    if (moduleSettings["mentor_library"] == 1) {
      this.mentoringsDropdownMenu[1].splice(1, 0, {
        name: (this as any).$t("headerMenu.headerMenuItemMyLibrary"),
        icon: "my-library",
        action: () => {
          const path = "/my-library";
          if (this.$route.path !== path)
            if (this.$route.path !== path) this.$router.push(path);
        }
      });
    }
    if (moduleSettings["mentoring_session"] == 1) {
      this.mentoringsDropdownMenu[0].splice(1, 0, {
        name: (this as any).$t("headerMenu.headerMenuItemOpenSessionSlots"),
        icon: "tea-icon-mentor",
        action: () => {
          const path = "/agenda";
          if (this.$route.path !== path)
            this.$router.push({
              path: path,
              //@ts-ignore
              query: { manageAgenda: true }
            });
        }
      });
      this.mentoringsDropdownMenu[1].splice(0, 0, {
        name: (this as any).$t("headerMenu.headerMenuItemMySessions"),
        icon: "tea-table",
        action: () => {
          const path = "/sessions";
          if (this.$route.path !== path) this.$router.push(path);
        }
      });
    }

    if (moduleSettings["mentorcast"] == 1) {
      this.discoverDropdownMenu.splice(0, 0, {
        name: (this as any).$t("headerMenu.headerMenuItemDiscoverMentorcasts"),
        icon: "scene",
        status: 1,
        action: () => {
          const path = "/discover/mentorcasts";
          if (this.$route.path !== path) this.$router.push(path);
        }
      });
      this.mentoringsDropdownMenu[0].splice(0, 0, {
        name: (this as any).$t("headerMenu.headerMenuItemLaunchMentorcasts"),
        icon: "mentorcasts-mentor",
        action: () => {
          const path = "/mentorcasts/create";
          if (this.$route.path !== path) this.$router.push(path);
        }
      });
      this.mentoringsDropdownMenu[1].splice(0, 0, {
        name: (this as any).$t("headerMenu.headerMenuItemMyMentorcasts"),
        icon: "scene",
        action: () => {
          const path = "/mentorcasts";
          if (this.$route.path !== path) this.$router.push(path);
        }
      });
    }

    if (moduleSettings["mentoring_program"] == 1) {
      this.mentoringsDropdownMenu[0].splice(2, 0, {
        name: (this as any).$t("headerMenu.headerMenuItemCreateProgram"),
        icon: "programs",
        action: () => {
          const path = "/mentoring-programs/create";
          if (this.$route.path !== path) this.$router.push(path);
        }
      });
      this.mentoringsDropdownMenu[1].splice(2, 0, {
        name: (this as any).$t("headerMenu.headerMenuItemMyPrograms"),
        icon: "program-price",
        action: () => {
          const path = "/mentoring-programs";
          if (this.$route.path !== path)
            if (this.$route.path !== path) this.$router.push(path);
        }
      });
    }

    this.loading = true;
    this.$store
      .dispatch("profile/GET_PROFILE")
      .then(() => {
        this.loading = false;
        this.$emit("setLoading", false);

        Socket().emit("online", this.profile.id);

        // // this.$store.dispatch('chat/GET_CHATS', {
        // 	page: 1
        // })

        Socket().on("notification", (data: any) => {
          this.playMessageSound();
          this.notification = data;
          this.$bvToast.show("notification-toast");
          this.$store.commit("profile/INCREASE_NOTIFICATION_COUNT");
          this.setNotifications(data);
        });

        const vm = this;
        Socket().on("chat-message", data => {
          vm.playMessageSound();

          if (data.user_id !== vm.profile.id) {
            if (vm.activeChatId != data.chat_id) {
              Socket().emit("message-delivered", {
                id: data.id,
                chat_id: data.chat_id,
                room_id: data.room_id
              });
              vm.$store.commit("profile/INCREASE_MESSAGE_COUNT", data.chat_id);
            }
            vm.$store.commit("chat/SET_MESSAGE_TO_CHAT", data);

            if (vm.activeChatId == data.chat_id) {
              Socket().emit("message-seen-delivered", {
                id: data.id,
                chat_id: data.chat_id,
                room_id: data.room_id
              });
              vm.$store.commit("message/APPEND_MESSAGE", data);
            }
          }
        });

        Socket().on("message-sent", data => {
          vm.$store.commit("message/MESSAGE_SENT", data);
        });

        Socket().on("message-seen-delivered", data => {
          vm.$store.commit("message/MESSAGE_SEEN_DELIVERED", data);
        });
        Socket().on("message-delivered", data => {
          // if (vm.activeChatId === data.chat_id) {

          vm.$store.commit("message/MESSAGE_DELIVERED", data);

          // }
        });

        Socket().on("invite-to-room", data => {
          //@ts-ignore
          const index = this.chats?.findIndex(i => i.id == data.chat_id);
          if (index > -1 && !this.chats[index].room_id) {
            this.chats[index].room_id = data.room;
          }
          Socket().emit("join-room", data);
        });
      })
      .catch(() => {
        this.loading = false;
        this.$emit("setLoading", false);
      });
  },
  beforeDestroy() {
    const events = [
      "notification",
      "chat-message",
      "message-sent",
      "message-seen-delivered",
      "message-delivered",
      "invite-to-room"
    ];
    for (const event of events) {
      Socket().off(event);
    }
  },
  methods: {
    playMessageSound() {
      //const audio = new Audio(require("@/assets/sounds/notification.wav"));
      //audio.play();
    },
    handleSearch() {
      console.log("Search button clicked!");
    },
    setNotifications(data: any) {
      const notification = {
        created_at: "1 sec ago",
        read_at: null,
        id: data.id,
        data
      };
      if (this.$refs["notifications-dropdown"]) {
        //@ts-ignore
        this.$refs["notifications-dropdown"].newNotification(notification);
      }
    }
  }
});
