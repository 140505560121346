
import Vue from "vue";

export default Vue.extend({
  name: "SearchResults",
  components: {
    // InfiniteLoading,
  },
  props: {
    profile: Object
  },
  data() {
    return {
      page: 1,
      list: [] as any,
      lastPage: 0,
      searchVal: "",
      isShow: false,
      loading: false
    };
  },
  computed: {
    emptyText() {
      //@ts-ignore
      return this.searchVal == ""
        ? (this as any).$t("setting.SearchResultsPlaceholder")
        : (this as any).$t("setting.SearchResultsNoRecordFoundLabel");
    }
  },
  watch: {
    searchVal() {
      this.onSearch(true);
    }
  },
  methods: {
    showSearch() {
      this.isShow = true;
    },
    onSearch(isDefault = false) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      if (isDefault) {
        this.list = [];
        this.lastPage = 0;
        this.page = 1;
      }

      this.$store
        .dispatch("search/SEARCH_USERS", {
          q: this.searchVal,
          page: this.page
        })
        .then(data => {
          if (data.data.length > 0) {
            this.lastPage = data.last_page;
            this.list.push(...data.data);
          }
          this.loading = false;
        });
    },
    handleScroll({
      target: { scrollTop, clientHeight, scrollHeight }
    }: {
      target: any;
    }) {
      if (
        scrollTop + clientHeight >= scrollHeight - 30 &&
        !this.loading &&
        this.page <= this.lastPage - 1
      ) {
        this.page += 1;
        this.onSearch();
      }
    },
    getExpertise(data: any) {
      return (
        data?.category_types["Expertise"] &&
        data?.category_types["Expertise"][0]
      );
    },
    getRoute(data: any) {
      if (data.type == "mentor") {
        return `/mentors/${data.uuid}`;
      } else {
        return `/mentorees/${data.uuid}`;
      }
    },
    onRoute() {
      this.searchVal = "";
      this.$store.commit("mentor/RESET_PAGE");
      this.$store.commit("mentee/RESET_PAGE");
      window.dispatchEvent(new CustomEvent("resetPage", {}));
      this.isShow = false;
    }
  }
});
