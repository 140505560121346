
import Vue from "vue";

export default Vue.extend({
  name: "NotificationToaster",
  props: {
    notification: Object
  },
  methods: {
    getNotificationText(text: string) {
      const pattern = /notification\.[a-zA-Z]+/;
      const match = pattern.exec(text);
      const extractedText = match ? match[0] : null;

      if (extractedText) {
        const notificationText = text.replace(
          pattern,
          (this as any).$t(extractedText)
        );
        return notificationText;
      } else {
        return text;
      }
    }
  }
});
